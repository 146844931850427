import ApiService from "../../helpers/ApiHelper";

class BookingService {


    static getBookings(payload) {

       const query = `?search=${JSON.stringify(payload.search)?JSON.stringify(payload.search):'1'}&page=${payload.page}&typeStatus=${payload.bookingsType}&pageSize=${payload.pageSize}`

        return ApiService.request(
          `/api/v1/bookings/${query}`,
          "get",
          null
        )
    }

    static addVet(payload) {
        return ApiService.request(
          "/admins/",
          "post",
          payload
        )
    }
    static addVet(payload) {
        return ApiService.request(
            "/admins/",
            "post",
            payload
        )
    }

    static removeVet(id) {
        return ApiService.request(
            `/users/${id}`,
            "delete",
            null
        )
    }

    static editVet(vet){
        return ApiService.request(
            `/users/${vet.id}`,
            "patch",
            vet
        )
    }
}

export default BookingService;
