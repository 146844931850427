import ApiService from "../../helpers/ApiHelper";
import dayjs from "dayjs";

const BASE_URL = `/api/v2/financial-settings`;

class FinancialSettingsService {
  static getFees({ feeType = "" }) {
    return ApiService.request(`${BASE_URL}/fees?feeType=${feeType}`, "get", {});
  }

  static async getFee(feeId = "") {
    if (!feeId) {
      throw new Error("Fee id is required");
    }
    const response = await ApiService.request(
      `${BASE_URL}/fees/${feeId}`,
      "get",
      {},
    );
    return {
      id: response._id,
      serviceCode: response.serviceCode,
      feeType: response.feeType,
      currency: response.currency,
      feeAmount: response.feeAmount,
      validFrom: response.validFrom ? dayjs(response.validFrom) : null,
      validTo: response.validTo ? dayjs(response.validTo) : null,
    };
  }

  static getSubscriptions() {
    return ApiService.request(`${BASE_URL}/subscriptions`, "get", {});
  }
  static async getSubscription(subscriptionId = "") {
    if (!subscriptionId) {
      throw new Error("Subscription id is required");
    }
    const response = await ApiService.request(
      `${BASE_URL}/subscriptions/${subscriptionId}`,
      "get",
      {},
    );
    return {
      id: response._id,
      currency: response.currency,
      feeAmount: response.feeAmount,
      validFrom: response.validFrom ? dayjs(response.validFrom) : null,
      validTo: response.validTo ? dayjs(response.validTo) : null,
    };
  }

  static addFee(fee) {
    return ApiService.request(`${BASE_URL}/fees`, "post", fee);
  }

  static addSubscription(subscription) {
    return ApiService.request(
      `${BASE_URL}/subscriptions`,
      "post",
      subscription,
    );
  }

  static removeFee(id) {
    return ApiService.request(`${BASE_URL}/fees/${id}`, "delete", null);
  }

  static removeSubscription(id) {
    return ApiService.request(
      `${BASE_URL}/subscriptions/${id}`,
      "delete",
      null,
    );
  }

  static updateFee(fee) {
    fee.id = fee?.id ? fee?.id : fee?._id;
    return ApiService.request(`${BASE_URL}/fees/${fee.id}`, "patch", fee);
  }

  static updateSubscription(subscription) {
    subscription.id = subscription?.id ? subscription?.id : subscription?._id;
    return ApiService.request(
      `${BASE_URL}/subscriptions/${subscription.id}`,
      "patch",
      subscription,
    );
  }
}

export default FinancialSettingsService;
