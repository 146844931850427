import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import React from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import {
  showEditSubscriptionModal,
  showRemoveSubscriptionModal,
} from "../../../store/financialSettings/actions";
import {
  dateHelper,
  isValidFee,
} from "../../../helpers/financial_settings_helper";

// feeAmount: joi.number().min(0).required(),
//   currency: joi
// .string()
// .uppercase()
// .valid(...Object.values(supportedCurrencies)),
//   validFrom: joi.date(),
//   validTo: joi.date(),
const SubscriptionsList = ({
  subscriptionList,
  showEditSubscriptionModal,
  showRemoveSubscriptionModal,
}) => {
  return (
    <div className="table-rep-plugin">
      <div className="table-responsive mb-0" data-pattern="priority-columns">
        <Table
          id="transaction-fees"
          className="table table-striped table-bordered"
        >
          <Thead>
            <Tr>
              <Th data-priority="1">Is Valid</Th>
              <Th data-priority="1">Amount</Th>
              <Th data-priority="2">Valid From</Th>
              <Th data-priority="2">Valid To</Th>
              <Th data-priority="3">Created At</Th>
              <Th data-priority="3">Updated At</Th>
              <Th data-priority="4">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {subscriptionList?.map((subs) => {
              const isValid = isValidFee(subs.validFrom, subs.validTo);
              return (
                <Tr key={subs._id}>
                  <Td className={`${isValid ? "bg-success" : "bg-warning"}`}>
                    {isValid ? "Yes" : "No"}
                  </Td>
                  <Td>{subs.feeAmount}</Td>
                  <Td>{dateHelper(subs.validFrom, "DD/MM/YYYY")}</Td>
                  <Td>{dateHelper(subs.validTo, "DD/MM/YYYY")}</Td>
                  <Td>{dateHelper(subs.createdAt)}</Td>
                  <Td>{dateHelper(subs.updatedAt)}</Td>
                  <Td>
                    <div className="button-items">
                      <Button
                        title="Delete fee"
                        color="danger"
                        type="button"
                        className="waves-effect waves-light"
                        onClick={() => {
                          showRemoveSubscriptionModal(subs._id);
                        }}
                      >
                        <i className="bx bx-trash-alt font-size-16 align-middle"></i>
                      </Button>
                      <Button
                        title="Edit fee"
                        type="button"
                        color="primary"
                        className="waves-effect waves-light"
                        onClick={() => {
                          showEditSubscriptionModal(subs._id);
                        }}
                      >
                        <i className="bx bxs-edit font-size-16 align-middle"></i>
                      </Button>
                    </div>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        {/*<pre>{JSON.stringify(list, null, 2)}</pre>*/}
      </div>
    </div>
  );
};

const mapStateToProps = ({ FinancialSettings }) => {
  return FinancialSettings;
};

export default connect(mapStateToProps, {
  showEditSubscriptionModal,
  showRemoveSubscriptionModal,
})(SubscriptionsList);
