import { FinancialSettingsActionTypes } from "./actionTypes";

export const getFees = (payload = {}) => {
  return {
    type: FinancialSettingsActionTypes.FEE_LIST_GET,
    payload: payload,
  };
};

export const getFeesSuccessful = (payload) => {
  return {
    type: FinancialSettingsActionTypes.FEE_LIST_GET_SUCCESSFUL,
    payload: payload,
  };
};

export const getFeesError = (payload) => {
  return {
    type: FinancialSettingsActionTypes.FEE_LIST_GET_ERROR,
    payload,
  };
};
export const loadFee = (payload = "") => {
  return {
    type: FinancialSettingsActionTypes.FEE_GET,
    payload: payload,
  };
};

export const loadFeeSuccessful = (payload) => {
  return {
    type: FinancialSettingsActionTypes.FEE_GET_SUCCESSFUL,
    payload: payload,
  };
};

export const loadFeeError = (payload) => {
  return {
    type: FinancialSettingsActionTypes.FEE_GET_ERROR,
    payload,
  };
};

export const addFee = (fee) => {
  return {
    type: FinancialSettingsActionTypes.FEE_ADD,
    payload: fee,
  };
};

export const addFeeSuccessful = (payload) => {
  return {
    type: FinancialSettingsActionTypes.FEE_ADD_SUCCESSFUL,
    payload,
  };
};

export const addFeeError = (payload) => {
  return {
    type: FinancialSettingsActionTypes.FEE_ADD_ERROR,
    payload,
  };
};

export const showAddFeeModal = (showModal) => {
  return {
    type: FinancialSettingsActionTypes.FEE_ADD_MODAL,
    payload: showModal,
  };
};

export const showAddFeeModalSuccess = (showModal) => {
  return {
    type: FinancialSettingsActionTypes.FEE_ADD_MODAL_SUCCESS,
    payload: showModal,
  };
};

export const showRemoveFeeModal = (showModal) => {
  return {
    type: FinancialSettingsActionTypes.FEE_SHOW_REMOVE_MODAL,
    payload: showModal,
  };
};
export const removeFee = (fee) => {
  return {
    type: FinancialSettingsActionTypes.FEE_REMOVE,
    payload: fee,
  };
};
export const removeFeeSuccess = (fee) => {
  return {
    type: FinancialSettingsActionTypes.FEE_REMOVE_SUCCESS,
    payload: fee?._id,
  };
};

export const removeFeeError = (error = "") => {
  return {
    type: FinancialSettingsActionTypes.FEE_REMOVE_ERROR,
    payload: error,
  };
};

export const showEditFeeModal = (showModal) => {
  return {
    type: FinancialSettingsActionTypes.FEE_SHOW_EDIT_MODAL,
    payload: showModal,
  };
};
export const updateFee = (fee) => {
  return {
    type: FinancialSettingsActionTypes.FEE_EDIT,
    payload: fee,
  };
};
export const editFeeSuccess = (fee) => {
  return {
    type: FinancialSettingsActionTypes.FEE_EDIT_SUCCESS,
    payload: fee,
  };
};
export const editFeeError = (fee) => {
  return {
    type: FinancialSettingsActionTypes.FEE_EDIT_ERROR,
    payload: fee,
  };
};

// subscriptions

export const getSubscriptions = (payload = {}) => {
  return {
    type: FinancialSettingsActionTypes.SUBSCRIPTION_LIST_GET,
    payload: payload,
  };
};

export const getSubscriptionsSuccessful = (payload) => {
  return {
    type: FinancialSettingsActionTypes.SUBSCRIPTION_LIST_GET_SUCCESSFUL,
    payload: payload,
  };
};

export const getSubscriptionsError = (payload) => {
  return {
    type: FinancialSettingsActionTypes.SUBSCRIPTION_LIST_GET_ERROR,
    payload,
  };
};

export const loadSubscription = (payload = {}) => {
  return {
    type: FinancialSettingsActionTypes.SUBSCRIPTION_GET,
    payload: payload,
  };
};

export const loadSubscriptionSuccessful = (payload) => {
  return {
    type: FinancialSettingsActionTypes.SUBSCRIPTION_GET_SUCCESSFUL,
    payload: payload,
  };
};

export const loadSubscriptionError = (payload) => {
  return {
    type: FinancialSettingsActionTypes.SUBSCRIPTION_GET_ERROR,
    payload,
  };
};

export const addSubscription = (subscription) => {
  return {
    type: FinancialSettingsActionTypes.SUBSCRIPTION_ADD,
    payload: subscription,
  };
};

export const addSubscriptionSuccessful = (payload) => {
  return {
    type: FinancialSettingsActionTypes.SUBSCRIPTION_ADD_SUCCESSFUL,
    payload,
  };
};

export const addSubscriptionError = (payload) => {
  return {
    type: FinancialSettingsActionTypes.SUBSCRIPTION_ADD_ERROR,
    payload,
  };
};
export const showAddSubscriptionModal = (showModal) => {
  return {
    type: FinancialSettingsActionTypes.SUBSCRIPTION_ADD_MODAL,
    payload: showModal,
  };
};
export const showAddSubscriptionModalSuccess = (showModal) => {
  return {
    type: FinancialSettingsActionTypes.SUBSCRIPTION_ADD_MODAL_SUCCESS,
    payload: showModal,
  };
};
export const showRemoveSubscriptionModal = (showModal) => {
  return {
    type: FinancialSettingsActionTypes.SUBSCRIPTION_SHOW_REMOVE_MODAL,
    payload: showModal,
  };
};
export const removeSubscription = (subscription) => {
  return {
    type: FinancialSettingsActionTypes.SUBSCRIPTION_REMOVE,
    payload: subscription,
  };
};
export const removeSubscriptionSuccess = (subscription) => {
  return {
    type: FinancialSettingsActionTypes.SUBSCRIPTION_REMOVE_SUCCESS,
    payload: subscription,
  };
};
export const removeSubscriptionError = (subscription) => {
  return {
    type: FinancialSettingsActionTypes.SUBSCRIPTION_REMOVE_ERROR,
    payload: subscription,
  };
};
export const showEditSubscriptionModal = (showModal) => {
  return {
    type: FinancialSettingsActionTypes.SUBSCRIPTION_SHOW_EDIT_MODAL,
    payload: showModal,
  };
};
export const updateSubscription = (subscription) => {
  return {
    type: FinancialSettingsActionTypes.SUBSCRIPTION_EDIT,
    payload: subscription,
  };
};
export const updateSubscriptionSuccess = (subscription) => {
  return {
    type: FinancialSettingsActionTypes.SUBSCRIPTION_EDIT_SUCCESS,
    payload: subscription,
  };
};
export const updateSubscriptionError = (subscription) => {
  return {
    type: FinancialSettingsActionTypes.SUBSCRIPTION_EDIT_ERROR,
    payload: subscription,
  };
};
