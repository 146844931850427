import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import {
  getFeesError,
  getFeesSuccessful,
  addFeeError,
  addFeeSuccessful,
  showAddFeeModalSuccess,
  removeFeeError,
  removeFeeSuccess,
  getFees,
  editFeeError,
  editFeeSuccess,
  getSubscriptions,
  getSubscriptionsSuccessful,
  getSubscriptionsError,
  addSubscriptionSuccessful,
  addSubscriptionError,
  showAddSubscriptionModalSuccess,
  removeSubscriptionSuccess,
  removeSubscriptionError,
  updateSubscriptionSuccess,
  updateSubscriptionError,
  loadFeeSuccessful,
  loadFeeError,
  loadSubscriptionSuccessful,
  loadSubscriptionError,
} from "./actions";
import FinancialSettingsService from "./service";
import { FinancialSettingsActionTypes } from "./actionTypes";

function* get({ payload }) {
  try {
    const response = yield call(FinancialSettingsService.getFees, payload);
    yield put(getFeesSuccessful(response));
  } catch (error) {
    yield put(getFeesError(error));
  }
}

function* getFee({ payload }) {
  try {
    const response = yield call(FinancialSettingsService.getFee, payload);
    yield put(loadFeeSuccessful(response));
  } catch (error) {
    yield put(loadFeeError(error));
  }
}

function* addFee({ payload: fee }) {
  try {
    const response = yield call(FinancialSettingsService.addFee, fee);
    yield put(addFeeSuccessful(response));
    yield put(getFees());
  } catch (error) {
    console.log(error);
    yield put(addFeeError(error));
  }
}

function* showAddFee({ payload: showModal }) {
  yield put(showAddFeeModalSuccess(showModal));
}

function* removeFee({ payload: feeId }) {
  try {
    const response = yield call(FinancialSettingsService.removeFee, feeId);
    yield put(removeFeeSuccess(response));
    yield put(getFees());
  } catch (error) {
    yield put(removeFeeError(error));
  }
}

function* editFee({ payload: fee }) {
  try {
    const response = yield call(FinancialSettingsService.updateFee, fee);
    yield put(editFeeSuccess(response));
    yield put(getFees());
  } catch (error) {
    yield put(editFeeError(error));
  }
}

// Subscriptions
function* listSubscriptions({ payload }) {
  try {
    const response = yield call(
      FinancialSettingsService.getSubscriptions,
      payload,
    );
    yield put(getSubscriptionsSuccessful(response));
  } catch (error) {
    yield put(getSubscriptionsError(error));
  }
}

function* getSubscription({ payload }) {
  try {
    const response = yield call(
      FinancialSettingsService.getSubscription,
      payload,
    );
    yield put(loadSubscriptionSuccessful(response));
  } catch (error) {
    yield put(loadSubscriptionError(error));
  }
}

function* addSubscription({ payload: subscription }) {
  try {
    const response = yield call(
      FinancialSettingsService.addSubscription,
      subscription,
    );
    yield put(addSubscriptionSuccessful(response));
    yield put(getSubscriptions());
  } catch (error) {
    yield put(addSubscriptionError(error));
  }
}

function* showAddSubscription({ payload: showModal }) {
  yield put(showAddSubscriptionModalSuccess(showModal));
}

function* removeSubscription({ payload: subscriptionId }) {
  try {
    const response = yield call(
      FinancialSettingsService.removeSubscription,
      subscriptionId,
    );
    yield put(removeSubscriptionSuccess(response));
    yield put(getSubscriptions());
  } catch (error) {
    yield put(removeSubscriptionError(error));
  }
}

function* editSubscription({ payload: subscription }) {
  try {
    const response = yield call(
      FinancialSettingsService.updateSubscription,
      subscription,
    );
    yield put(updateSubscriptionSuccess(response));
    yield put(getSubscriptions());
  } catch (error) {
    yield put(updateSubscriptionError(error));
  }
}

export function* watchGetSubscriptions() {
  yield takeEvery(
    FinancialSettingsActionTypes.SUBSCRIPTION_LIST_GET,
    listSubscriptions,
  );
}

export function* watchGetSubscription() {
  yield takeEvery(
    FinancialSettingsActionTypes.SUBSCRIPTION_GET,
    getSubscription,
  );
}

export function* watchAddSubscription() {
  yield takeEvery(
    FinancialSettingsActionTypes.SUBSCRIPTION_ADD,
    addSubscription,
  );
}

export function* watchShowAddSubscriptionModal() {
  yield takeEvery(
    FinancialSettingsActionTypes.SUBSCRIPTION_ADD_MODAL,
    showAddSubscription,
  );
}

export function* watchRemoveSubscription() {
  yield takeEvery(
    FinancialSettingsActionTypes.SUBSCRIPTION_REMOVE,
    removeSubscription,
  );
}

export function* watchEditSubscription() {
  yield takeEvery(
    FinancialSettingsActionTypes.SUBSCRIPTION_EDIT,
    editSubscription,
  );
}

export function* watchGetFees() {
  yield takeEvery(FinancialSettingsActionTypes.FEE_LIST_GET, get);
}

export function* watchGetFee() {
  yield takeEvery(FinancialSettingsActionTypes.FEE_GET, getFee);
}

export function* watchAddFee() {
  yield takeEvery(FinancialSettingsActionTypes.FEE_ADD, addFee);
}

export function* watchShowAddFeeModal() {
  yield takeEvery(FinancialSettingsActionTypes.FEE_ADD_MODAL, showAddFee);
}

export function* watchRemoveFee() {
  yield takeEvery(FinancialSettingsActionTypes.FEE_REMOVE, removeFee);
}

export function* watchEditFee() {
  yield takeEvery(FinancialSettingsActionTypes.FEE_EDIT, editFee);
}

function* financialSettingsSaga() {
  yield all([
    fork(watchGetFees),
    fork(watchGetFee),
    fork(watchAddFee),
    fork(watchShowAddFeeModal),
    fork(watchRemoveFee),
    fork(watchEditFee),
    fork(watchGetSubscriptions),
    fork(watchGetSubscription),
    fork(watchAddSubscription),
    fork(watchShowAddSubscriptionModal),
    fork(watchRemoveSubscription),
    fork(watchEditSubscription),
  ]);
}

export default financialSettingsSaga;
