import { Modal } from "reactstrap";
import React from "react";
import {
  Button,
  Col as ColAnt,
  DatePicker,
  Form,
  InputNumber,
  Select,
} from "antd";
import i18next from "i18next";
import { connect } from "react-redux";
import { addSubscription } from "../../../store/financialSettings/actions";
import { currencyOptions } from "../../../helpers/financial_settings_helper";

export const initialFormValues = {
  feeAmount: 0,
  currency: "BGN",
};

const AddSubscription = ({ open, onCancel, isLoading, addSubscription }) => {
  const [form] = Form.useForm();
  const onFinish = async (formData) => {
    try {
      const subscription = {
        currency: formData.currency,
        feeAmount: formData.feeAmount,
        validFrom: formData.validFrom ? formData.validFrom.toDate() : null,
        validTo: formData.validTo ? formData.validTo.toDate() : null,
      };
      addSubscription(subscription);
    } catch (err) {
      console.log(err?.response);
    }
  };
  const onFinishFailed = async (formData) => {
    console.log("on finish failed", formData);
  };

  return (
    <Modal isOpen={open} toggle={onCancel}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">Add Subscription</h5>
        <button
          type="button"
          onClick={() => {
            return onCancel();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form
          form={form}
          className="editing-form editing-form-steps"
          layout="vertical"
          name="subscriptionFee"
          autoComplete="on"
          initialValues={initialFormValues}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <ColAnt xs={24}>
            <Form.Item
              name="feeAmount"
              label="Amount"
              rules={[
                {
                  required: true,
                  message: "Subscription fee must be greater than 0",
                  type: "number",
                  min: 0.1,
                },
              ]}
            >
              <InputNumber
                placeholder="Amount"
                changeOnWheel
                min={0}
                size="middle"
                type="number"
              />
            </Form.Item>
            <Form.Item name="currency" label="Currency">
              <Select
                value="BGN"
                aria-readonly="true"
                options={currencyOptions}
                disabled
              ></Select>
            </Form.Item>
            <Form.Item
              name="validFrom"
              label={"Valid From"}
              className="input-box"
              rules={[
                {
                  type: "date",
                },
              ]}
            >
              <DatePicker className="input" format={"DD.MM.YYYY"} />
            </Form.Item>
            <Form.Item
              name="validTo"
              label={"Valid To"}
              className="input-box"
              rules={[
                {
                  type: "date",
                },
              ]}
            >
              <DatePicker className="input" format={"DD.MM.YYYY"} />
            </Form.Item>
          </ColAnt>
          <Form.Item>
            <Button htmlType="submit" className="" disabled={isLoading}>
              {i18next.t("Submit")}
            </Button>
          </Form.Item>
        </Form>
      </div>

      {isLoading && (
        <div className="modal-footer">
          <div className="spinner-border text-success m-1" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </Modal>
  );
};
const mapStateToProps = ({ FinancialSettings }) => {
  return FinancialSettings;
};
export default connect(mapStateToProps, {
  addSubscription,
})(AddSubscription);
