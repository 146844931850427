import {Pagination} from "antd";
import {useEffect, useState} from "react";

const Paging = ({setPage, pages, currentPage, setPageSize, pageSize}) => {

  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if(!totalPages || (pages && totalPages !== pages * pageSize)) {
      setTotalPages(pages * pageSize);
    }
  }, [pages]);


  return <Pagination aria-label="Page navigation"
                     className="ml-4 m-3"
                     simple
                     pageSize={pageSize}
                     defaultPageSize={20}
                     current={currentPage}
                     total={totalPages}
                     onChange={(page,pageSize) => {
                      setPage(page);
                      setPageSize(pageSize);
                     }} />;
}

export default Paging
