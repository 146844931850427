import React from "react";
import { Button, Modal } from "reactstrap";

const RemoveSubscription = ({ open, onCancel, onSubmit }) => {
  return (
    <Modal isOpen={open} toggle={onCancel}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          Remove Subscription
        </h5>
        <button
          type="button"
          onClick={onCancel}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <h5>Do you want to remove this Subscription?</h5>
      </div>
      <div className="modal-footer">
        <Button
          type="button"
          color="secondary"
          onClick={onCancel}
          className="waves-effect"
          data-dismiss="modal"
        >
          Cancel
        </Button>
        <Button
          color="danger"
          type="button"
          onClick={() => {
            if (!onSubmit) {
              console.log("Submit not provided");
            }
            onSubmit();
          }}
          className="waves-effect waves-light"
        >
          Yes
        </Button>
      </div>
    </Modal>
  );
};

export default RemoveSubscription;
