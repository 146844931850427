import React, { useEffect } from "react";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { connect } from "react-redux";
import {
  addFee,
  getFees,
  loadFee,
  removeFee,
  showAddFeeModal,
  showEditFeeModal,
  showRemoveFeeModal,
  updateFee,
} from "../../store/financialSettings/actions";
import FeesList from "./List/FeesList";
import AddFeeModal from "./Modals/AddFee";
import RemoveFeeModal from "./Modals/RemoveFee";
import EditFee from "./Modals/EditFee";

function TransactionFees({
  getFees,
  showAddFeeModal,
  showEditFeeModal,
  showRemoveFeeModal,
  isAddNewFeeOpen,
  isEditFeeOpen,
  isRemoveFeeOpen,
  currentFeeId,
  removeFee,
  ...props
}) {
  console.log({ props });
  useEffect(() => {
    if (getFees) {
      getFees();
    }
  }, []);
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Financial Settings"
            breadcrumbItem="Transaction Fees"
          />
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <Button
                    color="success"
                    className="waves-effect waves-light"
                    onClick={() => {
                      showAddFeeModal(true);
                    }}
                  >
                    <i className="bx bx-plus font-size-16 align-middle"> </i>{" "}
                    Add Fee
                  </Button>
                </CardHeader>
                <div className="d-inline-flex ant-row-space-between align-items-center"></div>
                <CardBody>
                  <FeesList />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <AddFeeModal
        open={isAddNewFeeOpen}
        onCancel={() => {
          showAddFeeModal(false);
        }}
      />
      <EditFee open={isEditFeeOpen} onCancel={() => showEditFeeModal(false)} />
      <RemoveFeeModal
        open={isRemoveFeeOpen}
        onCancel={() => {
          showRemoveFeeModal(false);
        }}
        onSubmit={() => {
          removeFee(currentFeeId);
        }}
      />
    </>
  );
}
const mapStateToProps = ({ FinancialSettings }) => {
  return FinancialSettings;
};
export default connect(mapStateToProps, {
  getFees,
  loadFee,
  showAddFeeModal,
  showEditFeeModal,
  showRemoveFeeModal,
  addFee,
  removeFee,
  updateFee,
})(TransactionFees);
