import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

export const currencyOptions = [
  { label: "BGN", value: "BGN" },
  { label: "USD", value: "USD" },
  { label: "EUR", value: "EUR" },
];
export const FEE_TYPE_SUBSCRIPTION = "SUBSCRIPTION";
export const FEE_TYPE_FIXED_FEE = "AMOUNT";
export const feeTypeOptions = [
  { label: "Subscription", value: FEE_TYPE_SUBSCRIPTION },
  { label: "Fixed Fee", value: FEE_TYPE_FIXED_FEE },
];

const dbStringFormat = "YYYY-MM-DDTHH:mm:ss.SSSZ";
export const dateHelper = (
  dateStr,
  format = "DD/MM/YYYY HH:mm:ss",
  defaultValue = "-",
) => {
  if (!dateStr) {
    return defaultValue;
  }
  const date = dayjs(dateStr, dbStringFormat);
  if (!date.isValid()) {
    return defaultValue;
  }
  return date.format(format);
};

export const isValidFee = (validFromStr = "", validToStr = "") => {
  if (validFromStr.trim() === "" && validToStr.trim() === "") {
    // no validation is set, assume permanently valid
    return true;
  }
  const validFrom = dayjs(validFromStr, dbStringFormat);
  const validTo = dayjs(validToStr, dbStringFormat);
  if (!validFrom.isValid() && !validTo.isValid()) {
    // invalid date strings
    return false;
  }
  const today = dayjs();
  let isValid = true;
  if (validFrom.isValid() && validFrom.isAfter(today)) {
    isValid = false;
  }
  if (validTo.isValid() && validTo.isBefore(today)) {
    isValid = false;
  }
  return isValid;
};
