import React, { useEffect } from "react";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { connect } from "react-redux";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  getSubscriptions,
  showAddSubscriptionModal,
  showEditSubscriptionModal,
  showRemoveSubscriptionModal,
} from "../../store/financialSettings/actions";
import SubscriptionsList from "./List/SubscriptionsList";
import AddSubscriptionModal from "./Modals/AddSubscription";
import RemoveSubscriptionModal from "./Modals/RemoveSubscription";
import EditSubscriptionModal from "./Modals/EditSubscription";

function Subscriptions({
  getSubscriptions,
  showAddSubscriptionModal,
  showRemoveSubscriptionModal,
  showEditSubscriptionModal,
  isAddNewSubscriptionOpen,
  isEditSubscriptionOpen,
  isRemoveSubscriptionOpen,
}) {
  useEffect(() => {
    if (getSubscriptions) {
      getSubscriptions();
    }
  }, []);
  return (
    <>
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs
            title="Financial Settings"
            breadcrumbItem="Subscription Fees"
          />
          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <Button
                    color="success"
                    className="waves-effect waves-light"
                    onClick={() => {
                      showAddSubscriptionModal(true);
                    }}
                  >
                    <i className="bx bx-plus font-size-16 align-middle"> </i>{" "}
                    Add Subscription
                  </Button>
                </CardHeader>
                <div className="d-inline-flex ant-row-space-between align-items-center"></div>
                <CardBody>
                  <SubscriptionsList />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <AddSubscriptionModal
        open={isAddNewSubscriptionOpen}
        onCancel={() => showAddSubscriptionModal(false)}
      />
      <RemoveSubscriptionModal
        open={isRemoveSubscriptionOpen}
        onCancel={() => showRemoveSubscriptionModal(false)}
      />
      {isEditSubscriptionOpen && (
        <EditSubscriptionModal
          open={isEditSubscriptionOpen}
          onCancel={() => showEditSubscriptionModal(false)}
        />
      )}
    </>
  );
}
const mapStateToProps = ({
  FinancialSettings: {
    isAddNewSubscriptionOpen,
    isEditSubscriptionOpen,
    isRemoveSubscriptionOpen,
  },
}) => {
  return {
    isAddNewSubscriptionOpen,
    isEditSubscriptionOpen,
    isRemoveSubscriptionOpen,
  };
};
export default connect(mapStateToProps, {
  getSubscriptions,
  showAddSubscriptionModal,
  showEditSubscriptionModal,
  showRemoveSubscriptionModal,
})(Subscriptions);
