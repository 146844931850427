import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Button,
  Col,
  Empty,
  Form,
  Input,
  List,
  Radio,
  Row,
  Typography,
} from "antd";
import {
  dateHelper,
  FEE_TYPE_FIXED_FEE,
  FEE_TYPE_SUBSCRIPTION,
  feeTypeOptions,
  isValidFee,
} from "../../../helpers/financial_settings_helper";
import { userRols } from "../../../helpers/userRols";
import {
  getFees,
  getSubscriptions,
} from "../../../store/financialSettings/actions";

const metaDataName = (...name) => ["metadata", ...name];

const Fees = ({ feeList = [], onChange, activeId }) => {
  if (!feeList) return null;
  if (feeList.length === 0) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
          <Typography.Text>No fixed fees configured</Typography.Text>
        }
      />
    );
  }
  return (
    <List
      header="Fixed Fees"
      bordered
      itemLayout="horizontal"
      dataSource={feeList.filter((fee) => {
        if (fee.feeType !== FEE_TYPE_FIXED_FEE) {
          return false;
        }
        return isValidFee(fee.validFrom, fee.validTo);
      })}
      renderItem={(item, i) => (
        <List.Item
          style={{
            border: `${item._id === activeId ? "2px solid green" : "2px solid transparent"}`,
          }}
          key={i}
          actions={[<Button onClick={() => onChange(item)}>Set Fee</Button>]}
        >
          <List.Item.Meta
            title={item.serviceCode}
            description={`Valid: from ${dateHelper(item.validFrom, "DD MMM YYYY")}, to ${dateHelper(item.validTo, "DD MMM YYYY")}`}
          />
          <span>{`${item.feeAmount} BGN`}</span>
        </List.Item>
      )}
    />
  );
};
const History = ({ historyList = [] }) => {
  if (!historyList) return null;
  if (historyList.length === 0) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={<Typography.Text>No fees history</Typography.Text>}
      />
    );
  }
  return (
    <List
      header="Fee History"
      bordered
      itemLayout="horizontal"
      dataSource={historyList}
      renderItem={(item, i) => (
        <List.Item
          style={{
            border: "2px solid transparent",
          }}
          key={i}
        >
          <List.Item.Meta
            title={`${item.serviceCode ?? "N/A"} - ${item.feeType}`}
            description={`Applied on ${dateHelper(item.appliedFrom, "DD MMM YYYY")} ${item.appliedTo ? `to ${dateHelper(item.appliedTo, "DD MMM YYYY")}` : " - current"}`}
          />
          <span>{`${item.feeAmount} BGN`}</span>
        </List.Item>
      )}
    />
  );
};

const Subscriptions = ({ subscriptionList = [], onChange, activeId }) => {
  if (!subscriptionList) return null;
  if (subscriptionList.length === 0) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={
          <Typography.Text>No subscriptions configured</Typography.Text>
        }
      />
    );
  }
  return (
    <List
      header="Subscriptions"
      bordered
      itemLayout="horizontal"
      dataSource={subscriptionList.filter((sub) => {
        return isValidFee(sub.validFrom, sub.validTo);
      })}
      renderItem={(item) => (
        <List.Item
          style={{
            border: `${item._id === activeId ? "2px solid green" : "2px solid transparent"}`,
          }}
          actions={[<Button onClick={() => onChange(item)}>Subscribe</Button>]}
        >
          <List.Item.Meta
            description={`Valid: from ${dateHelper(item.validFrom, "DD MMM YYYY")}, to ${dateHelper(item.validTo, "DD MMM YYYY")}`}
          />
          <span>{`${item.feeAmount} BGN`}</span>
        </List.Item>
      )}
    />
  );
};

const ProfessionalSubscriptions = ({
  admin,
  metaData = {},
  feeList,
  subscriptionList,
  getSubscriptions,
  getFees,
}) => {
  const {
    feeType = FEE_TYPE_FIXED_FEE,
    feeAmount,
    feeCurrency = "BGN",
    fee = null,
    feeHistory,
  } = metaData;
  const form = Form.useFormInstance();
  const [type, setType] = useState(feeType);
  const [feeUpdate, setFeeUpdate] = useState(fee);
  useEffect(() => {
    if (type === FEE_TYPE_FIXED_FEE) {
      getFees();
    } else if (type === FEE_TYPE_SUBSCRIPTION) {
      getSubscriptions();
    }
  }, [type]);

  useEffect(() => {
    if (feeUpdate) {
      console.log({ feeUpdate });
      form.setFieldValue(metaDataName("feeAmount"), feeUpdate.feeAmount);
      form.setFieldValue(metaDataName("feeCurrency"), feeUpdate.currency);
      form.setFieldValue(metaDataName("fee"), feeUpdate);
      console.log("form values", form.getFieldValue("metadata"));
    }
  }, [feeUpdate]);
  return (
    <Row gutter={16} style={{ gap: 16 }}>
      <Col span={12}>
        <Form.Item
          initialValue={feeType}
          name={metaDataName("feeType")}
          label="Fee Type"
          onChange={({ target }) => {
            setFeeUpdate(null);
            setType(target.value);
          }}
          rules={[
            {
              required: true,
              message: "Please enter fee type",
            },
          ]}
        >
          <Radio.Group options={feeTypeOptions} optionType="button" />
        </Form.Item>
      </Col>
      <Col span={12}>
        {admin.role === userRols.superAdmin ? (
          <Form.Item
            label="Fee Amount"
            name={metaDataName("feeAmount")}
            rules={[{ required: true, message: "Please write Fee Amount" }]}
            initialValue={feeAmount || 5}
          >
            <Input placeholder="Fee" />
          </Form.Item>
        ) : (
          <Form.Item label="Fee Amount">
            <Input readOnly value={feeAmount} />
          </Form.Item>
        )}
      </Col>
      <Col span={12}>
        <Form.Item
          label="Fee Currency"
          name={metaDataName("feeCurrency")}
          initialValue={feeCurrency}
        >
          <Input readOnly placeholder="Fee" />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item name={metaDataName("fee")} noStyle>
          {feeUpdate && (
            <div>
              <Row style={{ gap: "20px", color: "gray" }}>
                <p>Selected fee:</p>
                <strong>{feeUpdate?.serviceCode}</strong>
                <span>{feeUpdate?.feeType}</span>
                <span>{feeUpdate?.feeAmount}</span>
                <span>{feeUpdate?.currency}</span>
                <em>{`Valid: from ${dateHelper(feeUpdate.validFrom, "DD MMM YYYY")}, to ${dateHelper(feeUpdate.validTo, "DD MMM YYYY")}`}</em>
              </Row>
            </div>
          )}
        </Form.Item>
      </Col>
      <Col span={24}>
        {type === FEE_TYPE_FIXED_FEE && (
          <Fees
            feeList={feeList}
            onChange={(fee) => setFeeUpdate(fee)}
            activeId={feeUpdate?._id}
          />
        )}
        {type === FEE_TYPE_SUBSCRIPTION && (
          <Subscriptions
            subscriptionList={subscriptionList}
            onChange={(fee) => setFeeUpdate(fee)}
            activeId={feeUpdate?._id}
          />
        )}
      </Col>
      <Col span={24}>
        <History historyList={feeHistory} />
      </Col>
    </Row>
  );
};

const mapStateToProps = ({
  FinancialSettings: { feeList, subscriptionList },
}) => {
  return {
    feeList,
    subscriptionList,
  };
};
export default connect(mapStateToProps, {
  getSubscriptions,
  getFees,
})(ProfessionalSubscriptions);
