import { FinancialSettingsActionTypes } from "./actionTypes";

const initialState = {
  feeListError: null,
  feeList: null,
  isLoadingFee: false,
  isAddNewFeeOpen: false,
  isEditFeeOpen: false,
  isRemoveFeeOpen: false,
  subscriptionListError: null,
  subscriptionList: null,
  isLoadingSubscription: false,
  isAddNewSubscriptionOpen: false,
  isEditSubscriptionOpen: false,
  isRemoveSubscriptionOpen: false,
  message: null,
  currentFeeId: null,
  currentSubscriptionId: null,
  fee: null,
  subscription: null,
};

const financialSettings = (state = initialState, action) => {
  switch (action.type) {
    case FinancialSettingsActionTypes.FEE_LIST_GET:
      state = {
        ...state,
        feeList: null,
        feeListError: null,
      };
      break;
    case FinancialSettingsActionTypes.FEE_LIST_GET_SUCCESSFUL:
      state = {
        ...state,
        feeList: action.payload,
        feeListError: null,
      };
      break;
    case FinancialSettingsActionTypes.FEE_LIST_GET_ERROR:
      state = {
        ...state,
        feeList: null,
        feeListError: action.payload,
      };
      break;
    case FinancialSettingsActionTypes.FEE_GET:
      state = {
        ...state,
        fee: null,
        feeError: null,
        isLoadingFee: true,
        currentFeeId: action.payload,
      };
      break;
    case FinancialSettingsActionTypes.FEE_GET_SUCCESSFUL:
      state = {
        ...state,
        fee: action.payload,
        feeError: null,
        isLoadingFee: false,
      };
      break;
    case FinancialSettingsActionTypes.FEE_GET_ERROR:
      state = {
        ...state,
        fee: null,
        feeError: action.payload,
        isLoadingFee: false,
      };
      break;
    case FinancialSettingsActionTypes.FEE_ADD_MODAL:
      state = {
        ...state,
        isAddNewFeeOpen: action.payload,
      };
      break;
    case FinancialSettingsActionTypes.FEE_ADD_MODAL_SUCCESS:
      state = {
        ...state,
        isAddNewFeeOpen: action.payload,
      };
      break;
    case FinancialSettingsActionTypes.FEE_ADD:
      state = {
        ...state,
        isLoadingFee: true,
      };
      break;
    case FinancialSettingsActionTypes.FEE_ADD_SUCCESSFUL:
      state = {
        ...state,
        isLoadingFee: false,
        isAddNewFeeOpen: false,
      };
      break;
    case FinancialSettingsActionTypes.FEE_ADD_ERROR:
      state = {
        ...state,
        isLoadingFee: false,
        isAddNewFeeOpen: false,
        message: action.payload,
      };
      break;
    case FinancialSettingsActionTypes.FEE_SHOW_REMOVE_MODAL:
      const isRemoveFeeOpen = !!action.payload;
      const currentFeeId =
        typeof action.payload === "string" ? action.payload : null;
      state = {
        ...state,
        isRemoveFeeOpen,
        currentFeeId,
      };
      break;
    case FinancialSettingsActionTypes.FEE_SHOW_EDIT_MODAL:
      {
        const isEditFeeOpen = !!action.payload;
        const currentFeeId =
          typeof action.payload === "string" ? action.payload : null;
        state = {
          ...state,
          isEditFeeOpen,
          currentFeeId,
        };
      }
      break;
    case FinancialSettingsActionTypes.FEE_EDIT_SUCCESS:
      {
        // optimistic update of the fees, they will be fetched from api anyway
        const feeList = state?.feeList?.map((fee) => {
          if (fee._id !== action.payload._id) {
            return fee;
          }
          return action.payload;
        });

        state = {
          ...state,
          feeList,
          isEditFeeOpen: false,
        };
      }
      break;
    case FinancialSettingsActionTypes.FEE_REMOVE_SUCCESS:
      const feeList = state?.feeList?.filter(
        (fee) => fee._id !== action.payload,
      );
      state = {
        ...state,
        feeList,
        isRemoveFeeOpen: false,
      };
      break;
    case FinancialSettingsActionTypes.FEE_REMOVE_ERROR:
      state = {
        ...state,
        isRemoveFeeOpen: false,
      };
      break;
    case FinancialSettingsActionTypes.SUBSCRIPTION_LIST_GET:
      state = {
        ...state,
        subscriptionList: null,
        subscriptionListError: null,
      };
      break;
    case FinancialSettingsActionTypes.SUBSCRIPTION_LIST_GET_SUCCESSFUL:
      state = {
        ...state,
        subscriptionList: action.payload,
        subscriptionListError: null,
      };
      break;
    case FinancialSettingsActionTypes.SUBSCRIPTION_LIST_GET_ERROR:
      state = {
        ...state,
        subscriptionList: null,
        subscriptionListError: action.payload,
      };
      break;
    case FinancialSettingsActionTypes.SUBSCRIPTION_GET:
      state = {
        ...state,
        subscription: null,
        subscriptionError: null,
        isLoadingSubscription: true,
        currentSubscriptionId: action.payload,
      };
      break;
    case FinancialSettingsActionTypes.SUBSCRIPTION_GET_SUCCESSFUL:
      state = {
        ...state,
        subscription: action.payload,
        subscriptionError: null,
        isLoadingSubscription: false,
      };
      break;
    case FinancialSettingsActionTypes.SUBSCRIPTION_GET_ERROR:
      state = {
        ...state,
        subscription: null,
        subscriptionError: action.payload,
        isLoadingSubscription: false,
      };
      break;
    case FinancialSettingsActionTypes.SUBSCRIPTION_ADD_MODAL:
      state = {
        ...state,
        isAddNewSubscriptionOpen: action.payload,
      };
      break;
    case FinancialSettingsActionTypes.SUBSCRIPTION_ADD_MODAL_SUCCESS:
      state = {
        ...state,
        isAddNewSubscriptionOpen: action.payload,
      };
      break;
    case FinancialSettingsActionTypes.SUBSCRIPTION_ADD:
      state = {
        ...state,
        isLoadingSubscription: true,
      };
      break;
    case FinancialSettingsActionTypes.SUBSCRIPTION_ADD_SUCCESSFUL:
      state = {
        ...state,
        isLoadingSubscription: false,
        isAddNewSubscriptionOpen: false,
      };
      break;
    case FinancialSettingsActionTypes.SUBSCRIPTION_ADD_ERROR:
      state = {
        ...state,
        isLoadingSubscription: false,
        isAddNewSubscriptionOpen: false,
      };
      break;
    case FinancialSettingsActionTypes.SUBSCRIPTION_SHOW_REMOVE_MODAL:
      const isRemoveSubscriptionOpen = !!action.payload;
      const currentSubscriptionId =
        typeof action.payload === "string" ? action.payload : null;
      state = {
        ...state,
        isRemoveSubscriptionOpen,
        currentSubscriptionId,
      };
      break;
    case FinancialSettingsActionTypes.SUBSCRIPTION_REMOVE_SUCCESS:
      state = {
        ...state,
        subscriptionList: state?.subscriptionList?.filter(
          (subscription) => subscription._id !== action.payload,
        ),
        isLoadingSubscription: false,
        isAddNewSubscriptionOpen: false,
      };
      break;
    case FinancialSettingsActionTypes.SUBSCRIPTION_REMOVE_ERROR:
      state = {
        ...state,
        isLoadingSubscription: false,
        isAddNewSubscriptionOpen: false,
      };
      break;
    case FinancialSettingsActionTypes.SUBSCRIPTION_SHOW_EDIT_MODAL:
      {
        const isEditSubscriptionOpen = !!action.payload;
        const currentSubscriptionId =
          typeof action.payload === "string" ? action.payload : null;
        state = {
          ...state,
          isEditSubscriptionOpen,
          currentSubscriptionId,
        };
      }
      break;
    case FinancialSettingsActionTypes.SUBSCRIPTION_EDIT_SUCCESS:
      {
        // optimistic update of the fees, they will be fetched from api anyway
        const subscriptionList = state?.subscriptionList?.map(
          (subscription) => {
            if (subscription._id !== action.payload._id) {
              return subscription;
            }
            return action.payload;
          },
        );

        state = {
          ...state,
          subscriptionList,
          isEditSubscriptionOpen: false,
        };
      }
      break;
    default:
      break;
  }
  return state;
};

export default financialSettings;
