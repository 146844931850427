import { Modal } from "reactstrap";
import React, { useEffect } from "react";
import {
  Button,
  Col as ColAnt,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Select,
} from "antd";
import i18next from "i18next";
import { connect } from "react-redux";
import { loadFee, updateFee } from "../../../store/financialSettings/actions";
import {
  currencyOptions,
  feeTypeOptions,
} from "../../../helpers/financial_settings_helper";

const EditFee = ({
  feeId,
  fee,
  loadFee,
  updateFee,
  open,
  onCancel,
  isLoading,
}) => {
  const [form] = Form.useForm();
  useEffect(() => {
    // if current feed id is missing, skip
    if (!feeId) {
      return;
    }
    // if fee is loaded, skip
    if (fee && fee.id === feeId) {
      return;
    }
    loadFee(feeId);
  }, [feeId]);

  useEffect(() => {
    if (!fee || isLoading) {
      return;
    }
    form.setFieldsValue(fee);
  }, [fee]);

  const onFinish = async (formData) => {
    try {
      const fee = {
        ...formData,
        validFrom: formData.validFrom ? formData.validFrom.toDate() : null,
        validTo: formData.validTo ? formData.validTo.toDate() : null,
      };
      updateFee(fee);
    } catch (err) {
      console.log(err?.response);
    }
  };
  const onFinishFailed = async (formData) => {
    console.log("on finish failed", formData);
  };

  return (
    <Modal isOpen={open} toggle={onCancel}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">Edit Transaction Fee</h5>
        <button
          type="button"
          onClick={() => {
            return onCancel();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Form
          disabled={!fee || isLoading}
          form={form}
          className="editing-form editing-form-steps"
          layout="vertical"
          name="transactionFee"
          autoComplete="on"
          initialValues={fee}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <ColAnt xs={24}>
            <Form.Item noStyle name="id">
              <Input type="hidden" />
            </Form.Item>
            <Form.Item
              name="serviceCode"
              label="Fee Code"
              rules={[
                {
                  required: true,
                  message: "Please enter fee code",
                  type: "string",
                  min: 3,
                  max: 50,
                },
              ]}
            >
              <Input placeholder="Fee Code" maxLength={50} size="middle" />
            </Form.Item>
            <Form.Item
              name="feeType"
              label="Fee Type"
              rules={[
                {
                  required: true,
                  message: "Please enter fee type",
                },
              ]}
            >
              <Radio.Group options={feeTypeOptions} optionType="button" />
            </Form.Item>
            <Form.Item
              name="feeAmount"
              label="Fee Amount"
              rules={[
                {
                  required: true,
                  message: "Transaction fee must be greater than 0",
                  type: "number",
                  min: 0.1,
                },
              ]}
            >
              <InputNumber
                placeholder="Fee Amount"
                changeOnWheel
                min={0}
                size="middle"
                type="number"
              />
            </Form.Item>
            <Form.Item name="currency" label="Currency">
              <Select
                value="BGN"
                aria-readonly="true"
                options={currencyOptions}
                disabled
              ></Select>
            </Form.Item>
            <Form.Item
              name="validFrom"
              label={"Valid From"}
              className="input-box"
              rules={[
                {
                  type: "date",
                },
              ]}
            >
              <DatePicker className="input" format={"DD.MM.YYYY"} />
            </Form.Item>
            <Form.Item
              name="validTo"
              label={"Valid To"}
              className="input-box"
              rules={[
                {
                  type: "date",
                },
              ]}
            >
              <DatePicker className="input" format={"DD.MM.YYYY"} />
            </Form.Item>
          </ColAnt>
          <Form.Item>
            <Button htmlType="submit" className="" disabled={isLoading}>
              {i18next.t("Update")}
            </Button>
          </Form.Item>
        </Form>
      </div>

      {isLoading && (
        <div className="modal-footer">
          <div className="spinner-border text-success m-1" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </Modal>
  );
};
const mapStateToProps = ({
  FinancialSettings: { currentFeeId, fee, isLoadingFee },
}) => {
  return { feeId: currentFeeId, fee, isLoading: isLoadingFee };
};

export default connect(mapStateToProps, {
  updateFee,
  loadFee,
})(EditFee);
