import { all } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuditLogSaga from "./log/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import LayoutSaga from "./layout/saga";
import BlogSaga from "./blog/saga";
import AdminSaga from "./admin/saga";
import PaymentSaga from "./payment/saga";
import ParentSaga from "./parent/saga";
import UserSaga from "./users/saga";
import MetaHeadSaga from "./metaHead/saga";
import ProfessionalSaga from "./professionals/saga";
import BookingSaga from "./booking/saga";
import CouponSaga from "./coupons/saga";
import InitiatedBookingSaga from "./initiatedBookings/saga";
import PetSaga from "./pet/saga";
import AccountantSaga from "./accountant/saga";
import BlogBgSaga from "./blogbg/saga";
import TransactionsSaga from "./transactions/saga";
import TranslationSaga from "./translations/saga";
import invoicingSaga from "./invoicing/saga";
import receivedsSaga from "./received/saga";
import profPaymentsSaga from "./profPayments/saga";
import profReportSaga from "./profReport/saga";
import financialSettingsSaga from "./financialSettings/saga";

export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    AuditLogSaga(),
    AuthSaga(),
    ForgetSaga(),
    LayoutSaga(),
    BlogSaga(),
    AdminSaga(),
    PaymentSaga(),
    ParentSaga(),
    UserSaga(),
    ProfessionalSaga(),
    BookingSaga(),
    CouponSaga(),
    InitiatedBookingSaga(),
    PetSaga(),
    AccountantSaga(),
    BlogBgSaga(),
    TransactionsSaga(),
    TranslationSaga(),
    MetaHeadSaga(),
    invoicingSaga(),
    receivedsSaga(),
    profPaymentsSaga(),
    profReportSaga(),
    financialSettingsSaga(),
  ]);
}
