import { Table, Tbody, Td, Th, Thead, Tr } from "react-super-responsive-table";
import React from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";
import {
  showEditFeeModal,
  showRemoveFeeModal,
} from "../../../store/financialSettings/actions";
import {
  dateHelper,
  isValidFee,
} from "../../../helpers/financial_settings_helper";

export const FeesList = ({ feeList, showEditFeeModal, showRemoveFeeModal }) => {
  return (
    <div className="table-rep-plugin">
      <div className="table-responsive mb-0" data-pattern="priority-columns">
        <Table
          id="transaction-fees"
          className="table table-striped table-bordered"
        >
          <Thead>
            <Tr>
              <Th data-priority="1">Is Valid</Th>
              <Th data-priority="1">Service Code</Th>
              <Th data-priority="1">Fee Type</Th>
              <Th data-priority="1">Fee Amount</Th>
              <Th data-priority="2">Valid From</Th>
              <Th data-priority="2">Valid To</Th>
              <Th data-priority="3">Created At</Th>
              <Th data-priority="3">Updated At</Th>
              <Th data-priority="4">Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {feeList?.map((fee) => {
              const isValid = isValidFee(fee.validFrom, fee.validTo);
              return (
                <Tr key={fee._id}>
                  <Td className={`${isValid ? "bg-success" : "bg-warning"}`}>
                    {isValid ? "Yes" : "No"}
                  </Td>
                  <Td>{fee.serviceCode}</Td>
                  <Td>{fee.feeType}</Td>
                  <Td>{fee.feeAmount}</Td>
                  <Td>{dateHelper(fee.validFrom, "DD/MM/YYYY")}</Td>
                  <Td>{dateHelper(fee.validTo, "DD/MM/YYYY")}</Td>
                  <Td>{dateHelper(fee.createdAt)}</Td>
                  <Td>{dateHelper(fee.updatedAt)}</Td>
                  <Td>
                    <div className="button-items">
                      <Button
                        title="Delete fee"
                        color="danger"
                        type="button"
                        className="waves-effect waves-light"
                        onClick={() => {
                          showRemoveFeeModal(fee._id);
                        }}
                      >
                        <i className="bx bx-trash-alt font-size-16 align-middle"></i>
                      </Button>
                      <Button
                        title="Edit fee"
                        type="button"
                        color="primary"
                        className="waves-effect waves-light"
                        onClick={() => {
                          showEditFeeModal(fee._id);
                        }}
                      >
                        <i className="bx bxs-edit font-size-16 align-middle"></i>
                      </Button>
                    </div>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        {/*<pre>{JSON.stringify(list, null, 2)}</pre>*/}
      </div>
    </div>
  );
};

const mapStateToProps = ({ FinancialSettings }) => {
  return FinancialSettings;
};

export default connect(mapStateToProps, {
  showEditFeeModal,
  showRemoveFeeModal,
})(FeesList);
