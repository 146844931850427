import axios from "axios";
import baseURL from "../config";

class ApiService {
  static request(url, method, payload) {
    const token = (axios.defaults.headers.common["Authorization"] =
      localStorage.getItem("token"));
    if (token) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + token;
    }

    return axios[method](baseURL + url, payload)
      .then((response) => {
        if (
          response.data.result ||
          response.data.status === "success" ||
          response.data.status === "succeeded"
        ) {
          return response.data.data;
        } else if (response.statusText.toLowerCase() === "ok") {
          return response.data;
        } else {
          throw response.data.error;
        }
      })
      .catch((error) => {
        throw error;
      });
  }
}

export default ApiService;
