export const FinancialSettingsActionTypes = {
  FEE_LIST_GET: "FEE_LIST_GET",
  FEE_LIST_GET_SUCCESSFUL: "FEE_LIST_GET_SUCCESSFUL",
  FEE_LIST_GET_ERROR: "FEE_LIST_GET_ERROR",

  FEE_GET: "FEE_GET",
  FEE_GET_SUCCESSFUL: "FEE_GET_SUCCESSFUL",
  FEE_GET_ERROR: "FEE_GET_ERROR",

  FEE_ADD: "FEE_ADD",
  FEE_ADD_SUCCESSFUL: "FEE_ADD_SUCCESSFUL",
  FEE_ADD_ERROR: "FEE_ADD_ERROR",

  FEE_ADD_MODAL: "FEE_ADD_MODAL",
  FEE_ADD_MODAL_SUCCESS: "FEE_ADD_MODAL_SUCCESS",

  FEE_SHOW_REMOVE_MODAL: "FEE_SHOW_REMOVE_MODAL",
  FEE_SHOW_REMOVE_MODAL_SUCCESS: "FEE_SHOW_REMOVE_MODAL_SUCCESS",

  FEE_REMOVE: "FEE_REMOVE",
  FEE_REMOVE_SUCCESS: "FEE_REMOVE_SUCCESS",
  FEE_REMOVE_ERROR: "FEE_REMOVE_ERROR",

  FEE_SHOW_EDIT_MODAL: "FEE_SHOW_EDIT_MODAL",
  FEE_EDIT: "FEE_EDIT",
  FEE_EDIT_SUCCESS: "FEE_EDIT_SUCCESS",
  FEE_EDIT_ERROR: "FEE_EDIT_ERROR",

  // subscriptions
  SUBSCRIPTION_LIST_GET: "SUBSCRIPTION_LIST_GET",
  SUBSCRIPTION_LIST_GET_SUCCESSFUL: "SUBSCRIPTION_LIST_GET_SUCCESSFUL",
  SUBSCRIPTION_LIST_GET_ERROR: "SUBSCRIPTION_LIST_GET_ERROR",

  SUBSCRIPTION_GET: "SUBSCRIPTION_GET",
  SUBSCRIPTION_GET_SUCCESSFUL: "SUBSCRIPTION_GET_SUCCESSFUL",
  SUBSCRIPTION_GET_ERROR: "SUBSCRIPTION_GET_ERROR",

  SUBSCRIPTION_ADD: "SUBSCRIPTION_ADD",
  SUBSCRIPTION_ADD_SUCCESSFUL: "SUBSCRIPTION_ADD_SUCCESSFUL",
  SUBSCRIPTION_ADD_ERROR: "SUBSCRIPTION_ADD_ERROR",

  SUBSCRIPTION_ADD_MODAL: "SUBSCRIPTION_ADD_MODAL",
  SUBSCRIPTION_ADD_MODAL_SUCCESS: "SUBSCRIPTION_ADD_MODAL_SUCCESS",

  SUBSCRIPTION_SHOW_REMOVE_MODAL: "SUBSCRIPTION_SHOW_REMOVE_MODAL",
  SUBSCRIPTION_SHOW_REMOVE_MODAL_SUCCESS:
    "SUBSCRIPTION_SHOW_REMOVE_MODAL_SUCCESS",

  SUBSCRIPTION_REMOVE: "SUBSCRIPTION_REMOVE",
  SUBSCRIPTION_REMOVE_SUCCESS: "SUBSCRIPTION_REMOVE_SUCCESS",
  SUBSCRIPTION_REMOVE_ERROR: "SUBSCRIPTION_REMOVE_ERROR",

  SUBSCRIPTION_SHOW_EDIT_MODAL: "SUBSCRIPTION_SHOW_EDIT_MODAL",
  SUBSCRIPTION_EDIT: "SUBSCRIPTION_EDIT",
  SUBSCRIPTION_EDIT_SUCCESS: "SUBSCRIPTION_EDIT_SUCCESS",
  SUBSCRIPTION_EDIT_ERROR: "SUBSCRIPTION_EDIT_ERROR",

  DISABLE: "DISABLE",
  REMOVE: "REMOVE",
};
