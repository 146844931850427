import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";

//Dashboard
import Admin from "./admin/reducer";
import Accountant from "./accountant/reducer";
import AuditLog from "./log/reducer";
import Blog from "./blog/reducer";
import Booking from "./booking/reducer";
import Coupons from "./coupons/reducer";
import Payment from "./payment/reducer";
import Parent from "./parent/reducer";
import Users from "./users/reducer";
import Professional from "./professionals/reducer";
import InitiatedBookings from "./initiatedBookings/reducer";
import Pet from "./pet/reducer";
import BlogBg from "./blogbg/reducer";
import MetaHead from "./metaHead/reducer";
import Transactions from "./transactions/reducer";
import Translations from "./translations/reducer";
import { Invoicing, Invoices } from "./invoicing/reducer";
import Received from "./received/reducer";
import ProfPayments from "./profPayments/reducer";
import ProfReport from "./profReport/reducer";
import FinancialSettings from "./financialSettings/reducer";

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Blog,
  Admin,
  Payment,
  Parent,
  Professional,
  Booking,
  Coupons,
  InitiatedBookings,
  Pet,
  Accountant,
  AuditLog,
  BlogBg,
  Transactions,
  Translations,
  Users,
  MetaHead,
  Invoicing,
  Invoices,
  Received,
  ProfPayments,
  ProfReport,
  FinancialSettings,
});

export default rootReducer;
